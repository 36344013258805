<template>
	<div class="row h-100">
		<div class="col-12 mx-auto my-auto text-center">
			<div class="card auth-card">
				<div class="form-side">
					<img class="logo login-logo logo-full-width" />
					<h3 class="mb-4 auth-card-title">{{ $t('user.login-title-h3')}}</h3>
					<hr class="colorgraph">
					<div class="loginMessage" v-show="waitfor2fa">
						Please enter your e-mailtoken
					</div>
					<form @submit.prevent="formSubmit" class="signin-form">
						<div class="container-fluid p-0">
							<div class="row">
								<div class="col-md-12 form-group">
									<span class="float-left">{{ $t('user.username') }}:</span>
									<input type="text" name="username" :placeholder="$t('user.username')" class="form-control ph-login" v-model="username" />
								</div>
								<div class="col-md-12 form-group">
									<span class="float-left">{{ $t('user.password') }}:</span>
									<input type="password" name="password" :placeholder="$t('user.password')" class="form-control" v-model="password" />
								</div>
								<br />
								<div class="col-md-12 form-group" v-show="waitfor2fa">
									<span class="float-left">{{ $t('user.2fatoken') }}:</span>
									<input type="text" name="token" :placeholder="$t('user.token')" class="form-control" v-model="token" />
								</div>
								<div class="col-md-12">
									<button type="submit" class="btn btn-primary primary-color btn-lg btn-shadow btn-square w-100" :disabled="processing">{{ $t('user.login-button')}}</button>
								</div>
							</div>
						</div>
					</form>
					<br />
					<div class="d-flex justify-content-between align-items-center">
						<router-link tag="a" to="/page/user/forgot-password">{{ $t('user.forgot-password-question')}}</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { tsThisType } from '@babel/types';
import { mapGetters, mapActions } from 'vuex'

export default {
	data () {
		return {
			username: '',
			password: '',
			token: '',
		}
	},
	computed: {
		...mapGetters(['currentUser', 'waitfor2fa', 'processing', 'loginError'])
	},
	methods: {
		...mapActions(['login']),
		formSubmit () {
			this.login({ username: this.username, password: this.password, token: this.token }).then((response) => {
				if (response.status) {
					//check for organization
					if (response.data.organization_id && response.data.organization) {
						//check for 4pl
						if (response.data.organization_id == 2) {
							let url = process.env.VUE_APP_API_URL;
							if (!url) {
								url = window.location.href;
							}
							
							let switchOrganizationId = 3; //klm

							if (url.includes('transavia')) {
								switchOrganizationId = 150; //transavia
							}

							let organization = {
								'id': switchOrganizationId, // set default organization
							};
							this.switchOrganization(organization);
						} else {
							this.redirectOrganization(response.data.organization);
						}
					} else {
						let url = process.env.VUE_APP_API_URL;
						if (!url) {
							url = window.location.href;
						}

						window.location.href = url;
					}
				}
			});
			// reset token
			this.token = '';
		},
		switchOrganization(organization) {
			//set selected organization
			this.selectedOrganization = organization;

			//do switch
			this.req('GET', '/organization/doswitch/'+ this.selectedOrganization.id).then((response) => {
				if (response.status) {
					this.redirectOrganization(response.data.organization);
				}
			});
		},
		redirectOrganization(organization) {
			//use api url for matching subdomain
			let url = process.env.VUE_APP_API_URL;
			if (!url) {
				url = window.location.href;
			}
			let match = [];
			//returns true if the url has a word character with -
			//Including the . at the end
			//eslint-disable-next-line
			if (url.match('(.*://)(([\Da-z-]*)\.)')) {
				var numberCheck = /\d/;  
				//if match contains a digit only select http://
				//eslint-disable-next-line
				match = url.match('(.*://)(([\Da-z-]*)\.)');
				if(match[2] && numberCheck.test(match[2])) {
					match = url.match('(.*://)');
				}
			}
			
			if (match) {
				console.log(match);
				//Match 0 has the current subdomain with a dot
				//So the code bellow selects subdomain, and replaces it with new subdomain
				//if site has a subdomain add it to href
				if (organization && organization.subdomain_name !== null) {
					url = url.replace(match[0], match[1] + organization.subdomain_name + '.');
					url = url.replace('page/user/login', '');
				} else {
					url = url.replace('page/user/login', '');
				}
			}

			window.location.href = url;
		},
	},
	created () {
		if (this.$route.query.recovery !== undefined) {
			this.$router.push({ path: '/page/user/forgot-password', query: { recovery: this.$route.query.recovery } });
		}
	},
}
</script>
